import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $.setAjaxPagination = function(callback) {
      return $('.pagination a').click(function(event) {
	let loading = $('<div id="loading" style="display: none;"><span><img src="/assets/logo.png"/></span></div>')
	event.preventDefault()
	$('.other_images').prepend(loading)
	loading.fadeIn()
	$.ajax({
	  type: 'GET',
	  url: $(this).attr('href'),
	  dataType: 'script',
	  success: function() {
            return loading.fadeOut(function() {
              loading.remove()
              if (callback) {
		return callback()
              }
            })
	  }
	})
	return false
      })
    }

    $.setAjaxPagination()
  }
}
