import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  onLocationSelection(event) {
    this.filterFormTarget.elements['change_depth'].value = $(event.srcElement).closest('.region-row').find('#depth').val()

    Rails.fire(this.filterFormTarget, 'submit')
  }

  onLocationChoice(event) {
    let [data, status, xhr] = event.detail

    this.locationSelectorTarget.innerHTML = App.location_selector
    if (App.location_id) {
      this.ocFormTarget.elements['observation_collection[location_id]'].value = App.location_id
    }
  }

  onLocationSelectorChange(event) {
    let form = $(event.srcElement).closest('form')
    form.find('#observation_change_depth').val($(event.srcElement).closest('.selector-span').find('#observation_depth').val())
    Rails.fire(form.get(0), 'submit')
  }

  addNewLocation(event) {
    $(event.srcElement).closest('.area-col').find('#observation_add_new_region').val(true)
    Rails.fire($(event.srcElement).closest('form').get(0), 'submit')
    event.preventDefault()
  }

  onAreaInputChanged(event) {
    let button = $(event.srcElement).closest('.area-col').find('.add-button')

    if (event.srcElement.value != '') {
      button.show()
    } else {
      button.hide()
    }
  }

  toggleCompositeLocations(event) {
    event.preventDefault()

    let row = $(event.target).closest('.observation-row')
    row.find('.final').html(row.find('.all-locations').html())
    row.find('.add-area').hide()

    $(event.srcElement).removeClass('btn-outline-primary')
    $(event.srcElement).addClass('btn-primary')
  }
}
