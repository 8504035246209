import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'apiKeyResponse' ]
    
  onApiKeyGeneration(event) {
    let [data, status, xhr] = event.detail
    let response_data = JSON.parse(xhr.response)

    this.apiKeyResponseTarget.classList.remove("hidden")

    this.apiKeyResponseTarget.innerHTML = `Your new API key is: ${response_data.api_key}<br>Please make note of it. This will replace any previously set key.`
  }
}
