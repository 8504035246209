import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'lpForm', 'lpFormContainer']

  initialize() {
    App.initializeDatepickers()
    
    $('.map-container').each((index, element) => {
      let id = $(element).data('location-period-id')
       
      let map = L.map(`location-period-map-${id}`).fitBounds([[$(element).data('y-min'), $(element).data('x-min') ],[$(element).data('y-max'), $(element).data('x-max')]])
	
      L.tileLayer.provider('CartoDB.Positron').addTo(map)
      
      if ($(element).data('geojson'))
        L.geoJSON($(element).data('geojson')).addTo(map)
    })
  }
  
  launchGeojsonModal() {
    this.resetGeojsonModal()
    $('#update-geojson').modal()
  }

  resetGeojsonModal() {
    $('#error-explanation').html('')
    $('#geojson_file').val('')
    $('#geojson-updated').hide()
    $('.custom-file-label').html('')
  }
  
  onGeojsonUpdateError(event) {
    let [data, status, xhr] = event.detail;
    $('#geojson-updated').hide()
    $('#error-explanation').html(xhr.response)
    $('#error-explanation').show()
  }

  onGeojsonUpdateSuccess(event) {
    let [data, status, xhr] = event.detail
    $('#error-explanation').html('')
    $('#error-explanation').hide()
    $('#geojson-updated').html('The geojson field was successfully updated.')
    $('#geojson-updated').show()
  }

  onLocationPeriodUpdateError(event) {
    let [data, status, xhr] = event.detail;
    this.lpFormContainerTarget.innerHTML = xhr.response
    App.initializeaDatepickers()
  }
}
