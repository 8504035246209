import { Controller } from "stimulus"

require('leaflet/dist/leaflet')
require('leaflet-providers/leaflet-providers')
require('leaflet.sync')
require('leaflet-fullscreen/dist/Leaflet.fullscreen')
require('datatables.net/js/jquery.dataTables')
require('datatables.net-bs4/js/dataTables.bootstrap4')
require('datatables.net-scroller' )
require('d3/dist/d3')
require('d3-scale')
require('d3-simple-slider/dist/d3-simple-slider')
require('d3-svg-legend')

import { template } from "lodash"
import * as d3Base from 'd3'
import { sliderHorizontal } from 'd3-simple-slider'
import { legendColor } from 'd3-svg-legend'
import { legend } from 'src/legend'
import { responsivefy } from 'responsivefy'

// attach all d3 plugins to the d3 library
const d3 = Object.assign(d3Base, { sliderHorizontal, legendColor })

import { deserialize } from "deserialize-json-api"

const overlays = {
  water: {
    overlay: 'water',
    colormap: 'blues',
    range_l: 0.28244146704673767,
    range_r: 1
  },
  sanitation: {
    overlay: 'sani',
    colormap: 'reds',
    range_l: 0.28244146704673767,
    range_r: 1
  },
  cases: {
    overlay: 'cases',
    colormap: 'reds',
    range_l: 0,
    range_r: 4.582231044769287
  },
  rates: {
    overlay: 'rates',
    colormap: 'rdbu_r',
    range_l: 1.0000000116860974e-7,
    range_r: 0.00006835951353423297
  }
}

export default class extends Controller {
  static targets = [ 'locationList', 'locationSelector', 'locationForm', 'childLocationForm', 'childLocationError', 'assetImageLeft', 'assetImageRight', 'locationMap', 'countryMap', 'compositePreview', 'maiInfoTemplate', 'printableView', 'vaccinationFigure', 'vaccinationTable', 'vaccinationDataTemplate', 'epicurve', 'epicurveTitle' ]
  
  initialize() {
    this.maps = {}
    this.layers = {}
    this.legends = {}
    this.activeRight = '#summary-stats'
    
    this.initializeCountryMaps()

    //this.initializePrintableView()

    this.showSummaryStats()

    $(".unified-data-sets").dataTable({
      paging: false,
      searching: false,
      scrollY: 200,
      scrollCollapse: true
    })

    if (this.hasEpicurveTarget) {
      this.epicurveFigure = {}
      
      this.initializeEpicurve()
      this.renderEpicurve()
    }
  }

  initializePrintableView() {
    this.initializePersistenceFigure(`#dataviz-center`) 
    this.initializeCasesFigure(`#cases-center`)
    this.initializeSeasonalityFigure(`#seasonality`)
    this.initializeHotspotFigure(`#hotspot-center`)
  }

  showSummaryStats() {    
    let map = this.maps['mapleft']

    this.layers['suspected'] = L.geoJSON($('#latestSuspectedCard').data('geojson'), {
      style: (feature) => {
        return { color: '#01bda6', fillOpacity: 0.5}
      }})
      .setStyle({className: 'latest-suspected'})
      .addTo(map)

    this.layers['confirmed'] = L.geoJSON($('#latestConfirmedCard').data('geojson'), {
      style: (feature) => {
        return { className: 'latest-confirmed', color: '#6879ce', fillOpacity: 0.5}
      }}).addTo(map)

    if (this.activeRight != '#summary-stats') {
      this.clearSecondaryPanel()

      this.activeRight = '#summary-stats'

      $(this.activeRight).show()
    }
  }
  
  toggleMapSync() {
    if ( $('#sync_maps').prop('checked') ) {
      this.maps['mapleft'].sync(this.maps['mapright'])
      this.maps['mapright'].sync(this.maps['mapleft'])
    } else {
      this.maps['mapleft'].unsync(this.maps['mapright'])
      this.maps['mapright'].unsync(this.maps['mapleft'])
    }
  }

  initializeVaccinationTable() {    
    $("#vaccinations-table").dataTable({
      retrieve: true,
      paging: false,
      searching: false,
      scrollY: 400,
      order: [4, 'desc']})
  }
    
  initializeCountryMaps() {
    $('.map-container').each((index, element) => {
      let container = element.closest('.maps-container')

      if (! $(container).hasClass('hidden-flat')) {
	this.initializeMap(element)
      }
    })			     
  }

  initializeMap(map_container) {
    let id = $(map_container).data('location-period-id')

    let placement = $(map_container).data('placement')
    
    let mapName = `map${placement}`

    this.maps[mapName] = L.map(`location-period-map-${placement}`, {
      fullscreenControl: true,
      attributionControl: false})
      .fitBounds([[$(map_container).data('y-min'),
		   $(map_container).data('x-min') ],
		  [$(map_container).data('y-max'),
		   $(map_container).data('x-max')]])
    
    L.tileLayer.provider('CartoDB.Positron').addTo(this.maps[mapName])   
    
    if ($(map_container).data('geojson'))
      L.geoJSON($(map_container).data('geojson'), {
	style: (feature) => {
          return { color: '#76CCCE', fillOpacity: 0.05}
	}})
      .addTo(this.maps[mapName])
  }

  clearSecondaryPanel() {
    $(this.activeRight).hide()

    this.clearLayers('right')
  }
  
  /* SECTION - OVERLAYS */
  activateOverlay(srcElement, overlayName, placement) {
    if (placement == 'right' && this.activeRight != '#maps-right') {
      this.clearSecondaryPanel()

      this.activeRight = '#maps-right'

      $(this.activeRight).removeClass('hidden-flat')

      $(this.activeRight).show( () => {
	if (! this.maps['mapright']) {
	  this.initializeMap('.map-container-right')

	  this.applyOverlay(placement, overlayName)
	}
      })
    } else {
      this.applyOverlay(placement, overlayName)
    }

    $(`#maps-${placement}`).find(`.${overlayName}-caption`).show()
  }
  
  applyOverlay(placement, overlayName) {
    this.clearLayers(placement)
    
    let map = this.maps[`map${placement}`]
    
    let overlay = overlays[overlayName]
    
    let layerName = `${overlayName}Layer${placement}`
    
    if (this.layers[layerName]) {
      map.removeLayer(this.layers[layerName])
      
      this.layers[layerName] = null
    } else {      
      this.layers[layerName] = L.tileLayer(`${gon.terracotta_host}/singleband/${overlay.overlay}/{z}/{x}/{y}.png?colormap=${overlay.colormap}&stretch_range=[${overlay.range_l},${overlay.range_r}]`,
		   {opacity: 0.4})
	.addTo(map)
    }
  }

  clearOverlays(event) {
    this.clearLayers($(event.srcElement).closest('.button-controls').data('placement'))
  }
  
  clearLayers(placement) {
    let mapName = `map${placement}`
    
    Object.keys(overlays).forEach( (overlay) => {
      let layerName = `${overlay}Layer${placement}`
      let legendName = `${overlay}Legend${placement}`
  
      if (this.layers[layerName]) {
	this.maps[mapName].removeLayer(this.layers[layerName])

	this.layers[layerName] = null
      }
      
      if (this.legends[legendName]) {
	this.maps[mapName].removeControl(this.legends[legendName])

	this.legends[legendName] = null
      }
    })

    this.clearMapOverlays()

    $(`#maps-${placement} .caption`).hide()
  }

  clearMapOverlays() {
    let layers = ['suspected', 'confirmed', 'hotspots', 'vaccinations', 'seasonality', 'regional_ocs']

    layers.forEach( (layerName) => {
      if (this.layers[layerName]) {
	this.maps['mapleft'].removeLayer(this.layers[layerName])
	
	this.layers[layerName] = null
      }
    })			       
  }
  
  changeOpacity(e) {
    let value = $(e.srcElement).val()
    
    let container = $(e.srcElement).closest('.overlayControl')

    let overlayName = container.data('overlay') + 'Layer' + container.data('placement')

    let layer = this.layers[overlayName]

    if (layer != undefined)
      layer.setOpacity(value)
  } 
  
  sanitationOverlay(e) {
    let placement = $(e.srcElement).closest('.button-controls').data('placement')
    
    this.activateOverlay(e.srcElement, 'sanitation', placement)

    let map = this.maps[`map${placement}`]
    
    $(e.srcElement).closest('.country-map-container').find('.sanitation-caption').show()
    
    let mapLegend = legend(d3, {
      color: d3.scaleSequential([0, 100], d3.interpolateReds),
      title: "Sanitation access"
    })

    let legendName = `sanitationLegend${placement}`
    
    this.legends[legendName] = L.control({position: 'bottomright'})

    this.legends[legendName].onAdd = function (map) {
      var div = L.DomUtil.create('div', 'legend col')

      div.innerHTML = '<svg>' + mapLegend.html() + '</svg>'
      
      return div
    }

    this.legends[legendName].addTo(map);
  }
  
  waterOverlay(e) {
    let placement = $(e.srcElement).closest('.button-controls').data('placement')
    
    this.activateOverlay(e.srcElement, 'water', placement)

    let map = this.maps[`map${placement}`]
    
    $(e.srcElement).closest('.country-map-container').find('.water-caption').toggle()
    
    let mapLegend = legend(d3, {
      color: d3.scaleSequential([0, 100], d3.interpolateBlues),
      title: "Water access"
    })

    let legendName = `waterLegend${placement}`
    
    this.legends[legendName] = L.control({position: 'bottomright'})

    this.legends[legendName].onAdd = function (map) {
      var div = L.DomUtil.create('div', 'legend col')

      div.innerHTML = '<svg>' + mapLegend.html() + '</svg>'
      
      return div
    }

    this.legends[legendName].addTo(map)
  }

  casesOverlay(e) {
    let placement = $(e.srcElement).closest('.button-controls').data('placement')
    
    this.activateOverlay(e.srcElement,
			 'cases',
			 placement)

    let map = this.maps[`map${placement}`]

    $(e.srcElement).closest('.country-map-container').find('.cases-caption').show()

    let mapLegend = legend(d3, {
      color: d3.scaleDivergingLog([0.001, 1, 10000], d3.interpolateReds),
      title: "Cases",
      ticks: 7,
      tickFormat: "~g"
    })

    let legendName = `casesLegend${placement}`
    
    this.legends[legendName] = L.control({position: 'bottomright'})

    this.legends[legendName].onAdd = function (map) {
      var div = L.DomUtil.create('div', 'legend col')

      div.innerHTML = '<div><svg class="ml-2">' + mapLegend.html() + '</svg></div>'
      
      return div
    }

    this.legends[legendName].addTo(map)
  }

  ratesOverlay(e) {
    let placement = $(e.srcElement).closest('.button-controls').data('placement')
    
    this.activateOverlay(e.srcElement, 'rates', placement)

    let map = this.maps[`map${placement}`]

    let mapLegend = legend(d3, {
      color: d3.scaleDivergingLog([10000, 1, 0.0001], d3.interpolateRdBu),
      title: "Rates",
      ticks: 7,
      tickFormat: "~g"
    })

    let legendName = `ratesLegend${placement}`

    this.legends[legendName] = L.control({position: 'bottomright'})

    this.legends[legendName].onAdd = function (map) {
      var div = L.DomUtil.create('div', 'legend col')

      div.innerHTML = '<svg class="ml-2">' + mapLegend.html() + '</svg>'
      
      return div
    }

    this.legends[legendName].addTo(map)
  }

  /* SECTION - FIGURES */
  showPersistenceFigure(e) {
    let figures_container = $(event.srcElement).closest('.figures-container')
    let placement = figures_container.data('placement')
    let figure = figures_container.find('.mai-figure')

    figures_container.find('.active-figure').hide()
    
    figure.addClass('active-figure')
    figure.show()
    
    this.initializePersistenceFigure(`#dataviz-${placement}`)
  }

  showSeasonalityFigure(e) {
    this.clearLayers('right')

    $(this.activeRight).hide()

    this.activeRight = '#seasonality-right'

    $(this.activeRight).show( () => {
      this.initializeSeasonalityFigure(this.activeRight)
    })
  }

  showCasesFigure(srcElement) {
    let placement = $(srcElement).closest('.button-controls').data('placement')

    if (this.activeRight != '#cases-right') {
      this.clearSecondaryPanel()
      
      this.activeRight = '#cases-right'
      
      $(this.activeRight).show( () => {
	this.initializeCasesFigure('#cases-right')
      })
    }
  }
  
  initializeCasesFigure(viz_target) {
    var margin = {top: 10, right: 30, bottom: 30, left: 70},
	width = 460 - margin.left - margin.right,
	height = 200 - margin.top - margin.bottom;

    var x = d3.scaleBand()
	.rangeRound([0, width])
	.padding(0.1)

    var y = d3.scaleLinear()
	.rangeRound([height, 0])

    d3.selectAll(`${viz_target} > *`).remove()

    var svg = d3.select(viz_target)
	.append("svg")
	.attr("width", width + margin.left + margin.right)
	.attr("height", height + margin.top + margin.bottom)
	.call(responsivefy)
	.append("g")
	.attr("transform", 
	      "translate(" + margin.left + "," + margin.top + ")");
    
    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left) 
      .attr("x", 0 - (height / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Suspected Cases");

    // text label for the x axis
    svg.append("text")             
      .attr("transform",
            "translate(" + (width/2) + " ," + 
                           (height + margin.top + 20) + ")")
      .style("text-anchor", "middle")
      .text("Year");

    d3.csv("/cases.csv").then(function(data) {
      // format the data
      data.forEach(function(d) {
	d.cases = +d.cases;
      });

      // Scale the range of the data in the domains
      x.domain(data.map(function(d) { return d.year; }));
      y.domain([0, d3.max(data, function(d) { return d.cases; })]);

      // append the rectangles for the bar chart
      svg.selectAll(".bar")
	.data(data)
	.enter().append("rect")
	.attr("class", "bar")
	.attr("x", function(d) { return x(d.year); })
	.attr("width", x.bandwidth())
	.attr("y", function(d) { return y(d.cases); })
	.attr("height", function(d) { return height - y(d.cases); })
	.style("fill", "#183742")

      // add the x Axis
      svg.append("g")
	.attr("transform", "translate(0," + height + ")")
	.call(d3.axisBottom(x));
      
      // add the y Axis
      svg.append("g")
	.call(d3.axisLeft(y));
    });
  }
    
  initializePersistenceFigure(viz_target) {
    var margin = {top: 10, right: 30, bottom: 30, left: 60},
	width = 860 - margin.left - margin.right,
	height = 640 - margin.top - margin.bottom;

    d3.selectAll(`${viz_target} > *`).remove()
    
    // append the svg object to the body of the page
    var svg = d3.select(viz_target)
	.append("svg")
	.attr("width", width + margin.left + margin.right)
	.attr("height", height + margin.top + margin.bottom)
        .call(responsivefy)
	.append("g")
	.attr("transform",
              `translate(${margin.left}, ${margin.top})`)

    svg.append("g")
      .attr("class", "legendOrdinal")
      .attr("transform", `translate(${width - 40}, ${height - 80})`)
    
    // Add X axis
    var x = d3.scaleLinear()
    	  .domain([0, 20])
    	  .range([ 0, width ]);
    
    svg.append("g")
    	.attr("transform", "translate(0," + height + ")")
    	.call(d3.axisBottom(x));
    
    // Add Y axis
    var y = d3.scaleLinear()
      	  .domain([0, 40])
    	  .range([ height, 0]);
    
    svg.append("g")
      .call(d3.axisLeft(y));

    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x",0 - (height / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Mean Annual Incidence per 10,000 population")

    // text label for the x axis
    svg.append("text")             
      .attr("transform",
            "translate(" + (width/2) + " ," + 
                           (height + margin.top + 20) + ")")
      .style("text-anchor", "middle")
      .text("Persistence (% weeks)")
    
    d3.select('body')
      .append("div")
      .attr('id', 'tooltip')
      .style("opacity", 0)
      .style("position", 'absolute')
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px")

    // Continue to display tooltip on mouseover
    // d3.select("#tooltip").on('mouseout', function(d) {
    //   d3.select("#tooltip").on('mouseover', null)
      
    //   d3.select("#tooltip")
    // 	.style("opacity", "0")
    // });

    // Provide a reference to the controller for target lookup
    let controller = this

    var priorityScale = d3.scaleOrdinal().domain([1,2,3]).range(['#e87d72', '#53b64c', '#6c9df8'])
    var legendOrdinal = d3.legendColor().scale(priorityScale)
    
    svg.select(".legendOrdinal")
      .call(legendOrdinal)

    d3.csv("/example_ET_mai_persistence.csv").then( (data) => {
      svg.append('g')
      	.selectAll("dot")
      	.data(data)
      	.enter()
      	.append("circle")
      	.attr("cx", (d) => { return x(d.persistence) } )
      	.attr("cy", (d) => { return y(d.mai_10K) } )
      	.attr("r", 4)
      	.attr("fill",  d => { return priorityScale(d.priority) } )
        .on('mouseover', function (d) {
	  d3.select(this).attr('r', 8)

	  let maiInfoTemplate = template(controller.maiInfoTemplateTarget.innerHTML)

	  let html = maiInfoTemplate({
	    location_name: d.Location,
	    tl: d.TL,
	    tr: d.TR,
	    sCh: d.sCh,
	    weeks_with_cases: d.weeks_with_cases,
	    weeks_total: parseFloat(d.weeks_total).toFixed(2),
	    mai_10k: parseFloat(d.mai_10K).toFixed(2),
	    persistence: parseFloat(d.persistence).toFixed(2),
	    priority: d.priority
	  })
	  
	  d3.select('#tooltip')
	    .html(html)
	    .style('left', (d3.event.pageX+10) + 'px')
	    .style('top', (d3.event.pageY+10) + 'px')
	    .style("opacity", 1)
	})
	.on('mouseleave', function(d) {
       	  d3.select(this).attr('r', 4)

	  // move tooltip out of the way to prevent overlap
       	  d3.select('#tooltip')
	    .style('left', '0')
	    .style('top', '0')
      	    .style("opacity", 0)
       	})
    })
  }

  initializeSeasonalityFigure(viz_target) {
    this.clearMapOverlays()
    
    // set the dimensions and margins of the graph
    var margin = {top: 10,
		  right: 30,
		  bottom: 35,
		  left: 50},
	width = 560 - margin.left - margin.right,
	height = 400 - margin.top - margin.bottom;

    $(viz_target).show()

    d3.selectAll(`${viz_target} > *`).remove()
    
    // append the svg object to the body of the page
    var svg = d3.select(viz_target)
	.append("svg")
	.attr("width", width + margin.left + margin.right)
	.attr("height", height + margin.top + margin.bottom)
	.call(responsivefy)
	.append("g")
	.attr("transform",
              "translate(" + margin.left + "," + margin.top + ")")

    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - (height / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Seasonality")

    // text label for the x axis
    svg.append("text")             
      .attr("transform",
            "translate(" + (width/2) + " ," + 
                           (height + margin.top + 25) + ")")
      .style("text-anchor", "middle")
      .text("Month")
    
    
    var priorityScale = d3.scaleOrdinal().domain([1,2,3], ['', '', ''])    

    this.maps['mapleft'].invalidateSize()

    d3
      .json(`/covariate_collections/seasonality_collections/${gon.country_id}.json`)
      .then( (data) => {
	let collection = data.collection
	
	let means = collection.features.map( d => d.properties.lambda )

	let max = parseFloat(d3.max(means))
	let min = parseFloat(d3.min(means))
	let half = ((max - min) / 2) + min
	
	let colorScale = d3
	  .scaleLinear()
	    .domain([min, half, max])
	    .range(["#4a94e9", '#fff', "#f6c054"])

	let stool = (feature) => {
	  return {
            fillColor: colorScale(feature.properties.lambda),
            weight: 1,
            opacity: 1,
            color: 'black',
            dashArray: '5',
            fillOpacity: 0.3
	  }
	}

	this.layers['seasonality'] = L.geoJson(collection, {style: stool}).addTo(this.maps["mapleft"])

	//Read the data
	//let month_data = data.monthly_collection
	//console.log(month_data)
	
	// Add X axis --> it is a date format
	var x = d3.scaleLinear()
	    .domain([1,12])
	    .range([ 0, width ]);
	
	svg.append("g")
	  .attr("transform", "translate(0," + height + ")")
	  .call(d3.axisBottom(x));
	
	// Add Y axis
	var y = d3.scaleLinear()
	    .domain([-5, 5])
	    .range([ height, 0 ]);
	
	svg.append("g")
	  .call(d3.axisLeft(y));

	// Add the lines
	svg
	  .append("path")
	  .datum(data.monthly_collection.filter(function(d) { return d.data.param_fam == 'betas_g1' }))
	  .attr("fill", "none")
	  .attr("stroke", "#f6c054")
	  .attr("stroke-width", 3)
	  .attr("d", d3.line()
		.x(function(d) { return x(d.data.month) })
		.y(function(d) { return y(d.data.mean) })
               )
	
	svg
	  .append("path")
	  .datum(data.monthly_collection.filter(function(d) {  return d.data.param_fam == 'betas_g1' }))
	  .attr("fill", "#f6c054")
	  .attr("opacity","0.15")
	  .attr("stroke", "none")
	  .attr("d", d3.area()
		.x(function(d) { return x(d.data.month) })
		.y0(function(d) { return y(d.data['q975']) })
		.y1(function(d) { return y(d.data['q025']) })
               )
	
	svg
	  .append("path")
	  .datum(data.monthly_collection.filter(function(d) { return d.data.param_fam == 'betas_g2' }))
	  .attr("fill", "#4a94e9")
	  .attr("opacity","0.15")
	  .attr("stroke", "none")
	  .attr("d", d3.area()
		.x(function(d) { return x(d.data.month) })
		.y0(function(d) { return y(d.data['q975']) })
		.y1(function(d) { return y(d.data['q025']) })
               )
	
	svg
	  .append("path")
	  .datum(data.monthly_collection.filter(function(d) { return d.data.param_fam == 'betas_g2' }))
	  .attr("fill", "none")
	  .attr("stroke", "#4a94e9")
	  .attr("stroke-width", 3)
	  .attr("d", d3.line()
		.x(function(d) { return x(d.data.month) })
		.y(function(d) { return y(d.data.mean) })
               )
      })
  }

  initializeHotspotFigure(geo_target) {
    this.clearMapOverlays()
    
    var priorityScale = d3
	.scaleOrdinal()
	.domain([1,2,3])
	.range(['#e87d72', '#53b64c', '#6c9df8'])
    
    function style(feature) {
      return {
        fillColor: priorityScale(feature.properties.color_value),
        weight: 1,
        opacity: 1,
        color: 'black',
        dashArray: '5',
        fillOpacity: 0.3
      };
    }

    function getColor(d) {
      return d > 1000 ? '#800026' :
        d > 500  ? '#BD0026' :
        d > 200  ? '#E31A1C' :
        d > 100  ? '#FC4E2A' :
        d > 50   ? '#FD8D3C' :
        d > 20   ? '#FEB24C' :
        d > 10   ? '#FED976' :
        '#FFEDA0';
    }

    d3.json("/ETH_hotspots.geojson").then( (geojson) => {
      d3.csv("/ETH_hotspots.csv").then( (data) => {
	for (var i = 0; i < data.length; i++) {
	  for (var j = 0; j < geojson.features.length; j++)  {
	    let location_period_id = geojson.features[j].properties.location_period_id
	    
	    if (location_period_id == data[i].location_period_id) {
	      geojson.features[j].properties.color_value = data[i].value 

	      break;
	    }
	  }
	}

	this.layers['hotspots'] = L.geoJson(geojson, {
	  style: style,
	  onEachFeature: (feature, layer) => {
            layer.bindTooltip(feature.properties.color_value.toString() );
	  }}).addTo(this.maps["mapleft"])
      })
    })
    
    this.clearSecondaryPanel()
    
    this.activeRight = '#persistence-right'

    $(this.activeRight).show( () => {
      this.initializePersistenceFigure(`#persistence-right`)
    })
  }

  showHotspotsFigure(e) {
    this.clearSecondaryPanel()
    
    this.activeRight = '#persistence-right'

    $(this.activeRight).show( () => {
      this.initializeHotspotFigure(this.activeRight)
    })
  }

  showVaccination(event) {
    this.clearMapOverlays()

    this.clearSecondaryPanel()
    
    this.activeRight = '#vaccination-table'

    $(this.activeRight).show()

    this.initializeVaccinationTable()
    
    let style = (feature) => {
      return {
        fillColor: feature.properties.vaccination_round == 1 ? 'red' : 'blue',
        weight: 1,
        opacity: 1,
        color: 'black',
        dashArray: '5',
        fillOpacity: 0.3
      }
    }
    
    d3
      .json(`/covariate_collections/by_name_and_location/Vaccination%20Data/${gon.country_id}.json`)
      .then( (data) => {	
	this.layers['vaccinations'] = L.geoJson(data, {
	  style: style,
	  onEachFeature: (feature, layer) => {
	    let vTemplate = template(this.vaccinationDataTemplateTarget.innerHTML)

	    let tooltip = vTemplate({
	      doses: feature.properties.doses,
	      target_population: feature.properties.target_population,
	      round: feature.properties.vaccination_round
	    })

	    layer.bindTooltip(tooltip)
	  }}).addTo(this.maps["mapleft"])
      })
  }

  showRegionalObservationCollections(event) {
    this.clearMapOverlays()

    this.clearSecondaryPanel()
    
    this.activeRight = '#regional-oc-table'

    $(this.activeRight).show()

    $("#regional-ocs-table").dataTable({
      retrieve: true,
      paging: false,
      searching: false,
      scrollY: 400})
    
    let oc_ids = $('#regional-ocs-table').data('regional-oc-ids')

    let style = (feature) => {
      return {
        fillColor: '#76CCCE',
        weight: 1,
        opacity: 1,
        color: 'black',
        dashArray: '5',
        fillOpacity: 0.4
      }
    }
    
    d3
      .json(`/observation_collections/geojson_for_multiple_collections/${oc_ids}`)
      .then( (data) => {	
	this.layers['regional_ocs'] = L.geoJson(data, {
	  style: style,
	  onEachFeature: (feature, layer) => {
	    console.log(`feature${feature.id}`)
	    /*let vTemplate = template(this.vaccinationDataTemplateTarget.innerHTML)

	    let tooltip = vTemplate({
	      doses: feature.properties.doses,
	      target_population: feature.properties.target_population,
	      round: feature.properties.vaccination_round
	    })

	    layer.bindTooltip(tooltip)*/
	  }}).addTo(this.maps["mapleft"])
      })
  }


  latestSuspected(event) {
    //let table = $('#oc-table').DataTable()
    //console.log(table)
    //table.rows('#oc-row-20437').scrollTo()
    //table.row(30).scrollTo()
    //$("#oc-row-20437").scrollTo()
    //table.scroller.toPosition( 100 );
    //console.log( table.rows('.selected').data().length +' row(s) selected' )
  }

  latestConfirmed(event) {
    //$('#oc-table').row('#oc-row-20437').scrollTo()
  }

  selectObservationCollection(event) {
    let id = $(event.srcElement).closest('tr').data('observationCollectionId')

    d3.json(`/observation_collections/${id}/get_geojson`).then( (data) => {
      L.geoJson(data).addTo(this.maps["mapcenter"])
    })
  }

  initializeEpicurve() {
    this.epicurveFigure.oc_id = this.epicurveTarget.dataset.observationCollectionId
    
    this.epicurveFigure.margin = {top: 10, right: 30, bottom: 30, left: 40}
    this.epicurveFigure.width = 2560 - this.epicurveFigure.margin.left - this.epicurveFigure.margin.right
    this.epicurveFigure.height = 800 - this.epicurveFigure.margin.top - this.epicurveFigure.margin.bottom
        
    this.epicurveFigure.parseTime = d3.timeParse("%Y-%m-%d")
    this.epicurveFigure.formatTime = d3.timeFormat("%b. %Y")
   
    this.epicurveFigure.svg = d3.select('#epicurve')
      .append("svg")
      .attr("width", this.epicurveFigure.width +
	    this.epicurveFigure.margin.left +
	    this.epicurveFigure.margin.right)
      .attr("height", this.epicurveFigure.height +
	    this.epicurveFigure.margin.top +
	    this.epicurveFigure.margin.bottom)
      .call(responsivefy)
      .append("g")
      .attr("transform", 
	    `translate(${this.epicurveFigure.margin.left}, ${this.epicurveFigure.margin.top})`)
  }

  renderEpicurve(startDate = '', endDate = '') {
    this.epicurveFigure.svg.selectAll('*').remove()

    d3.json(`/observation_collections/${this.epicurveFigure.oc_id}/summary_for_epicurve/${startDate}/${endDate}`)
      .then( (data) => {
	this.epicurveTitleTarget.innerHTML = `Data Source: <a href="/observation_collections/${data.observation_collection_id}" target="_blank">Observation Collection ${data.observation_collection_id} <i class="fas fa-external-link-alt"></i><\a>`

	data.summary.forEach( (d) => {
	  d.date = this.epicurveFigure.parseTime(d.time_left)

	  d.start_date = this.epicurveFigure.parseTime(d.time_left)

	  d.end_date = this.epicurveFigure.parseTime(d.time_right)
	})

	let earliest_date = d3.min(data.summary, (d) => d.start_date)
	let latest_date = d3.max(data.summary, (d) => d.end_date)
	let numDays = Math.round( Math.abs(latest_date - earliest_date) / (24 * 60 * 60 * 1000) )

	if (numDays >= 365) {
	  this.epicurveFigure.formatTime = d3.timeFormat("%Y")
	} else if (numDays >= 28) {
	  this.epicurveFigure.formatTime = d3.timeFormat("%Y-%m")
	} else {
	  this.epicurveFigure.formatTime = d3.timeFormat("%Y-%m-%d")
	}
	
	let x = d3
	    .scaleBand()
	    .domain(data.summary.map( (d) => d.date) )
	    .rangeRound([0,
			 this.epicurveFigure.width])
	    .padding(0)
	
	let y = d3
	    .scaleLinear()
	    .domain([0,
		     d3.max(data.summary, (d) => d.suspected_cases)])
	    .range([this.epicurveFigure.height - this.epicurveFigure.margin.bottom,
		    0])
	
	this.epicurveFigure.svg
	  .append("g")
	  .attr("fill", "#c13414")
	  .selectAll("rect")
	  .data( data.summary.filter( (d) =>{ return d.suspected_cases !== null }) )
	  .join("rect")
	  .attr("x", (d) => x(d.date))
	  .attr("y", (d) => y(d.suspected_cases))
	  .attr("height", (d) => y(0) - y(d.suspected_cases))
	  .attr("width", x.bandwidth)
	
	this.epicurveFigure.svg
	  .append("g")
	  .attr("fill", "#a0a0a0")
	  .selectAll("rect")
	  .data( data.summary.filter( (d) =>{ return d.deaths !== null }) )
	  .join("rect")
	  .attr("x", (d) => x(d.date))
	  .attr("y", (d) => y(d.deaths))
	  .attr("height", (d) => y(0) - y(d.deaths))
	  .attr("width", x.bandwidth)


	let xScale = d3.scaleTime()
            .domain([earliest_date,
		     latest_date])
            .range([this.epicurveFigure.margin.left,
		    (this.epicurveFigure.width - this.epicurveFigure.margin.right)])
	
	let xAxis = d3
	    .axisBottom()
	    .scale(xScale)

	this.epicurveFigure.svg
	  .append("g")
	  .attr("class", "epicurve-axis")
	  .attr("transform",
		`translate(0, ${this.epicurveFigure.height - this.epicurveFigure.margin.bottom})`)
	  .call(xAxis)

	let yAxis = d3
	    .axisLeft(y)
	    .scale(y)
	
	this.epicurveFigure.svg
	  .append("g")
	  .attr("class", "epicurve-axis")
	  .attr("transform", `translate(${this.epicurveFigure.margin.left}, 0)`)
	  .call( yAxis )
	
	this.renderRangeSlider(data.summary)

	let summaryX = d3.scaleBand()
	    .domain(data.location_summary_result.map( (d) => { return d.region }))
            .range([0, this.epicurveFigure.width])
            .padding(0.1)
	
	let summaryY = d3.scaleLinear()
	    .domain([0,
		     d3.max(data.location_summary_result, (d) => d.suspected_cases)])
	    .range([this.epicurveFigure.height - this.epicurveFigure.margin.bottom,
		    this.epicurveFigure.margin.top])
      })
  }

  updateEpicurveCollection(event) {
    this.epicurveFigure.oc_id = event.srcElement.value

    this.epicurveFigure.oc_changed = true
    
    this.renderEpicurve()
  }

  renderRangeSlider (data) {
    let dates = data.map( d => d.date )
    
    let sliderTicks = Math.round(Object.keys(dates).length / 50)
    
    let sliderRange = d3
	.sliderBottom()
	.min( d3.min(dates) )
	.max( d3.max(dates) )
	.width(800)
	.tickFormat(this.epicurveFigure.formatTime)
	.ticks(sliderTicks)
	.default([d3.min(dates), d3.max(dates)])
	.fill('#2196f3')
	.on('end', val => {
	  d3.select('div#value-range').text(val.map(this.epicurveFigure.formatTime).join(' - '));

	  this.renderEpicurve( Number.isInteger(val[0]) ? Math.round(val[0] / 1000): '',
			       Number.isInteger(val[1]) ? Math.round(val[1] / 1000): '' )
	})

    if ( (! this.epicurveFigure.sliderRendered) ||
	 this.epicurveFigure.oc_changed ) {
      if ( this.epicurveFigure.oc_changed ) {
	d3.select('div#slider-range').select("svg").remove()
	
	this.epicurveFigure.slider_value.selectAll('*').remove()
	
	this.epicurveFigure.oc_changed = false
      }
    
      this.epicurveFigure.range_slider = d3
	  .select('div#slider-range')
	  .append('svg')
	  .attr('width', 1400)
	  .attr('height', 100)
	  .append('g')
	  .attr('transform', 'translate(50,50)');

      this.epicurveFigure.range_slider.call(sliderRange);

      this.epicurveFigure.slider_value = d3
	.select('#value-range')
	.text(
	  sliderRange
	    .value()
	    .map(this.epicurveFigure.formatTime)
	    .join(' - ')
	)

      this.epicurveFigure.sliderRendered = true
    }
  }
}
