import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'roleSelector', 'roleSelectorDescription' ]
  roleDescriptions = new Map( [
    ['registered_visitor', 'View "Complete" OCs, view Observations, download Observations from public OCs, '],
    ['data_enterer', 'Create OCs, create Locations, delete incomplete OCs'],
    ['data_manager', 'Manage observations, view restricted data'] ] )
    
  showRoleDescription(event) {
    if (event.srcElement.value != '') {
      $(this.roleSelectorDescriptionTarget).html( this.roleDescriptions.get(event.srcElement.value) )
      $(this.roleSelectorDescriptionTarget).collapse('show')
    } else {
      $(this.roleSelectorDescriptionTarget).html( '' )
      $(this.roleSelectorDescriptionTarget).collapse('hide')
    }
  }
}
