require("tablesorter")

global.moment = require('moment/moment');
require('tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4')

require('bootstrap-autocomplete/dist/latest/bootstrap-autocomplete')

global.App = {}

App.disableAutocompletion = () => {
  $('.no-autocomplete').attr('autocomplete', 'off')
}

App.initializeDatepickers = () => {
  $('.datepicker-control').datetimepicker({format: 'YYYY/MM/DD'})
  $('.datepicker-yf-control').datetimepicker({format: 'YYYY/MM/DD'})
}

$(document).on("turbolinks:load", function() {
  $("#country_search").autoComplete({
    minLength: 2,
    resolverSettings: {
      url: '/locations/country_search'
    }
  })

  $('#country_search').on('autocomplete.select', function(evt, item) {
    window.location.href = "/locations/" + item.value + "/country_profile";
  })

  $('.auto-submit').change(function() {
    return Rails.fire($(this).closest('form').get(0), 'submit');
  })

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })
  
  App.disableAutocompletion()
})
