/**
 * File generated by js-routes 2.1.2
 * Based on Rails 6.0.3.7 routes of CholeraTaxonomy::Application
 */
const __jsr = ((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const isBroswer = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define(routes) {
                Utils.namespace(Root, null, routes);
            },
            isSupported() {
                return !!Root;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {};
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBroswer && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return routes;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    return (object[part] = routes);
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /observation_collections/:id/activity_log(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activity_log_observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activity_log"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/:id/add_composite_location(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_composite_location_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_composite_location"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/add_interest/:interest_class/:interest_id(.:format)
 * @param {any} interest_class
 * @param {any} interest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_interest_path = __jsr.r({"interest_class":{"r":true},"interest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"add_interest"],[2,[7,"/"],[2,[3,"interest_class"],[2,[7,"/"],[2,[3,"interest_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id/add_observations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_observations_observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_observations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/activity_log(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_activity_log_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"activity_log"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/locations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/location_periods/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_location_period_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"location_periods"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/location_periods(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_location_periods_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"location_periods"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"locations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/observations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_observation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"observations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/observations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_observations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"observations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const authenticated_root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /locations/build_or_create_composite_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const build_or_create_composite_location_locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[6,"build_or_create_composite_location"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/location_periods/by_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const by_location_api_v1_location_periods_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"location_periods"],[2,[7,"/"],[2,[6,"by_location"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/observations/by_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const by_location_api_v1_observations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"observations"],[2,[7,"/"],[2,[6,"by_location"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/observations/by_observation_collections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const by_observation_collections_api_v1_observations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"observations"],[2,[7,"/"],[2,[6,"by_observation_collections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/countries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const countries_locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[6,"countries"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /locations/:id/country_audit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const country_audit_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"country_audit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/country_lookup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const country_lookup_locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[6,"country_lookup"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /locations/:id/country_profile(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const country_profile_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"country_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/:id/country_profile_printable(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const country_profile_printable_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"country_profile_printable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/country_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const country_search_locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[6,"country_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /covariate_collections/by_name_and_location/:name/:location_id(.:format)
 * @param {any} name
 * @param {any} location_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const covariate_collection_by_name_and_location_path = __jsr.r({"name":{"r":true},"location_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"covariate_collections"],[2,[7,"/"],[2,[6,"by_name_and_location"],[2,[7,"/"],[2,[3,"name"],[2,[7,"/"],[2,[3,"location_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /source_documents/create_and_attach(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_and_attach_source_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"source_documents"],[2,[7,"/"],[2,[6,"create_and_attach"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /missing_locations/:id/create_location(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_location_missing_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"missing_locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_location"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/d3(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const d3_locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[6,"d3"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /locations/:id/data_overview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_overview_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"data_overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observations_files/:id/delete_observations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_observations_observations_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observations_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_observations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/:id/deprecate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deprecate_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"deprecate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /location_migration_actions/:id/deprecate_original_location(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deprecate_original_location_location_migration_action_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"location_migration_actions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"deprecate_original_location"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/deprecated(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deprecated_locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[6,"deprecated"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id/destroy_observations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_observations_observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_observations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id/source_documents/:source_document_id(.:format)
 * @param {any} id
 * @param {any} source_document_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_source_document_path = __jsr.r({"id":{"r":true},"source_document_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"source_documents"],[2,[7,"/"],[2,[3,"source_document_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /location_periods/:id/download_geojson(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_geojson_location_period_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"location_periods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_geojson"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id/download_observations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_observations_observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_observations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/download_unified_dataset_for_location/:location_id/:tag_name(.:format)
 * @param {any} location_id
 * @param {any} tag_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_unified_dataset_path = __jsr.r({"location_id":{"r":true},"tag_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[6,"download_unified_dataset_for_location"],[2,[7,"/"],[2,[3,"location_id"],[2,[7,"/"],[2,[3,"tag_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/locations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/location_periods/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_location_period_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"location_periods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/observations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_observation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"observations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/:location_id/location_periods/:id/edit(.:format)
 * @param {any} location_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_location_location_period_path = __jsr.r({"location_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"location_id"],[2,[7,"/"],[2,[6,"location_periods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /location_migrations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_location_migration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"location_migrations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /location_migration_actions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_location_migration_action_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"location_migration_actions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /location_periods/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_location_period_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"location_periods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /missing_locations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_missing_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"missing_locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_observation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:observation_collection_id/observations/:id/edit(.:format)
 * @param {any} observation_collection_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_observation_collection_observation_path = __jsr.r({"observation_collection_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"observation_collection_id"],[2,[7,"/"],[2,[6,"observations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /observations_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_observations_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observations_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sheet_uploads/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_sheet_upload_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sheet_uploads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /source_documents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_source_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"source_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /who_regions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_who_region_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"who_regions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /application/exception(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exception_test_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"application"],[2,[7,"/"],[2,[6,"exception"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /locations/:id/find_children_with_associations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const find_children_with_associations_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"find_children_with_associations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/generate_api_key(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_api_key_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"generate_api_key"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /locations/:id/get_details(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_details_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id/get_geojson(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_geojson_observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_geojson"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /glossary(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const glossary_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"glossary"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /interest_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const interest_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"interest_list"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /locations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /locations/:location_id/location_periods/:id(.:format)
 * @param {any} location_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const location_location_period_path = __jsr.r({"location_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"location_id"],[2,[7,"/"],[2,[6,"location_periods"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/:location_id/location_periods(.:format)
 * @param {any} location_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const location_location_periods_path = __jsr.r({"location_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"location_id"],[2,[7,"/"],[2,[6,"location_periods"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /location_migrations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const location_migration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"location_migrations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /location_migration_actions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const location_migration_action_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"location_migration_actions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /location_migration_actions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const location_migration_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"location_migration_actions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /location_migrations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const location_migrations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"location_migrations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /location_periods/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const location_period_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"location_periods"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /location_periods(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const location_periods_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"location_periods"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"locations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /locations/by_region_map/:region_map(.:format)
 * @param {any} region_map
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const locations_by_region_map_path = __jsr.r({"region_map":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[6,"by_region_map"],[2,[7,"/"],[2,[3,"region_map"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/lookup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lookup_observation_collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[6,"lookup"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id/manage_observations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_observations_observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"manage_observations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/:id/merge_location(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merge_location_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"merge_location"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /missing_locations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const missing_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"missing_locations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /missing_locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const missing_locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"missing_locations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /observation_collections/modifications_report(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modifications_report_observation_collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[6,"modifications_report"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/locations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_location_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/location_periods/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_location_period_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"location_periods"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/observations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_observation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"observations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_location_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /locations/:location_id/location_periods/new(.:format)
 * @param {any} location_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_location_location_period_path = __jsr.r({"location_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"location_id"],[2,[7,"/"],[2,[6,"location_periods"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /location_migrations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_location_migration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"location_migrations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /location_migration_actions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_location_migration_action_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"location_migration_actions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /location_periods/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_location_period_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"location_periods"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /missing_locations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_missing_location_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"missing_locations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /observations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_observation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"observations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_observation_collection_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:observation_collection_id/observations/new(.:format)
 * @param {any} observation_collection_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_observation_collection_observation_path = __jsr.r({"observation_collection_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"observation_collection_id"],[2,[7,"/"],[2,[6,"observations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /observations_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_observations_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"observations_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sheet_uploads/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_sheet_upload_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sheet_uploads"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /source_documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_source_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"source_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /who_regions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_who_region_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"who_regions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /observations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const observation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:observation_collection_id/observations/:id(.:format)
 * @param {any} observation_collection_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const observation_collection_observation_path = __jsr.r({"observation_collection_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"observation_collection_id"],[2,[7,"/"],[2,[6,"observations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:observation_collection_id/observations(.:format)
 * @param {any} observation_collection_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const observation_collection_observations_path = __jsr.r({"observation_collection_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"observation_collection_id"],[2,[7,"/"],[2,[6,"observations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:observation_collection_id/set_primary_observation_range/:first_observation_id/:last_observation_id(.:format)
 * @param {any} observation_collection_id
 * @param {any} first_observation_id
 * @param {any} last_observation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const observation_collection_set_primary_observation_range_path = __jsr.r({"observation_collection_id":{"r":true},"first_observation_id":{"r":true},"last_observation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"observation_collection_id"],[2,[7,"/"],[2,[6,"set_primary_observation_range"],[2,[7,"/"],[2,[3,"first_observation_id"],[2,[7,"/"],[2,[3,"last_observation_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const observation_collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /observations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const observations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"observations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /observations_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const observations_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observations_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /observations_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const observations_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"observations_files"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id/observations_for_epicurve(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const observations_for_epicurve_observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"observations_for_epicurve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id/observations_page(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const observations_page_observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"observations_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /application/overview_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const overview_data_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"application"],[2,[7,"/"],[2,[6,"overview_data"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /source_documents/:id/pick(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pick_source_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"source_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pick"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /observations_files/:id/reapply_observations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reapply_observations_observations_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observations_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reapply_observations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /recent_activity(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recent_activity_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recent_activity"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /release_notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const release_notes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"release_notes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/remove_interest/:interest_class/:interest_id(.:format)
 * @param {any} interest_class
 * @param {any} interest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_interest_path = __jsr.r({"interest_class":{"r":true},"interest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"remove_interest"],[2,[7,"/"],[2,[3,"interest_class"],[2,[7,"/"],[2,[3,"interest_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /resources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resources"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /observations_files/:id/review_errors(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_errors_observations_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observations_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"review_errors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /source_documents/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_source_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"source_documents"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /covariate_collections/seasonality_collections/:location_id(.:format)
 * @param {any} location_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const seasonality_collections_by_location_id_path = __jsr.r({"location_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"covariate_collections"],[2,[7,"/"],[2,[6,"seasonality_collections"],[2,[7,"/"],[2,[3,"location_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id/second_entry(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const second_entry_observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"second_entry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/select_or_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_or_create_locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[6,"select_or_create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id/set_complete1(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_complete1_observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_complete1"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id/set_in_progress(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_in_progress_observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_in_progress"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id/set_observation_range_attribute(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_observation_range_attribute_observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_observation_range_attribute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/:id/set_verified(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_verified_observation_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_verified"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/setup_api_key(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setup_api_key_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"setup_api_key"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sheet_uploads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sheet_upload_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sheet_uploads"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sheet_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sheet_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sheet_uploads"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /locations/:id/show_card(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_card_location_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /source_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const source_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"source_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /source_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const source_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"source_documents"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /observation_collections/source_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const source_documents_observation_collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[6,"source_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/statuses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const statuses_observation_collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[6,"statuses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /location_periods/:id/update_geojson(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_geojson_location_period_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"location_periods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_geojson"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sheet_uploads/:id/verify(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const verify_sheet_upload_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sheet_uploads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"verify"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /who_regions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const who_region_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"who_regions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /who_regions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const who_regions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"who_regions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v1/locations/with_observation_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const with_observation_data_api_v1_locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[6,"with_observation_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/without_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const without_location_observation_collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[6,"without_location"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /observations/without_location_period(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const without_location_period_observations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"observations"],[2,[7,"/"],[2,[6,"without_location_period"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/locations/without_observation_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const without_observation_data_api_v1_locations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[6,"without_observation_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/without_observations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const without_observations_observation_collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[6,"without_observations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /observation_collections/workspace(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const workspace_observation_collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"observation_collections"],[2,[7,"/"],[2,[6,"workspace"],[1,[2,[8,"."],[3,"format"]]]]]]]);

