import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'documentForm', 'locationDocuments' ]

  initialize() {
    console.log('initialize')
  }

  onDocumentCreateSuccess(event) {
    const [data, status, xhr] = event.detail

    this.locationDocumentsTarget.innerHTML = xhr.responseText

    this.documentFormTarget.reset()

    document.getElementById(`direct-upload-1`).remove()
    document.getElementById(`location_document_file`).setAttribute('disabled', false)
  }
  
  onDocumentUpdateError(event) {
    const [data, status, xhr] = event.detail
    console.error(xhr.responseText)
    this.documentFormTarget.innerHTML = xhr.responseText
  }
}
